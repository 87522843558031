<template>
  <div>
    <section class="cont_01">
      <form @submit="onSearch">
        <div class="searchform_cont_01">
          <span>
            <label>운영기관</label>
            <span>
              <base-select
                :options="search.client.option"
                v-model="search.client.value"
                placeholder="선택"
              />
            </span>
          </span>
          <span>
            <label>미션</label>
            <span>
              <base-select
                :options="search.mission.option"
                v-model="search.mission.value"
                placeholder="선택"
              />
            </span>
          </span>
          <span>
            <label>획득일</label>
            <date-picker
              v-model="search.startDate.value"
              :popover="{ visibility: 'click' }"
              :max-date="new Date()"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  type="text"
                  :value="inputValue"
                  v-on="inputEvents"
                  class="dt_base"
                  readonly
                />
              </template>
            </date-picker>
            <span class="ml_04 mr_04">~</span>
            <date-picker
              v-model="search.endDate.value"
              :popover="{ visibility: 'click' }"
              :max-date="new Date()"
              :min-date="search.startDate.value"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  type="text"
                  :value="inputValue"
                  v-on="inputEvents"
                  class="dt_base"
                  readonly
                />
              </template>
            </date-picker>
            <base-button type="primary" nativeType="submit">검색</base-button>
            <!-- <label>검색어</label>
            <base-select
              :options="searchTypeOptions"
              v-model="search.type"
              placeholder="선택"
            />
            <input
              type="text"
              v-model="search.q"
              placeholder="검색어를 입력해 주세요."
            />
            <base-button type="primary" nativeType="submit">검색</base-button>
            -->
          </span>
        </div>
      </form>
    </section>
    <!-- <div class="total_box">
        <span>
          <base-select
            :options="localGovernments"
            v-model="selectedlocalGovernment"
            placeholder="선택"
          />
        </span>
        <span>
          <date-picker v-model="regStartDt" :popover="{ visibility: 'click' }">
            <template v-slot="{ inputValue, inputEvents }">
              <input
                type="text"
                :value="inputValue"
                v-on="inputEvents"
                class="dt_base"
              />
            </template>
          </date-picker>
          <span class="ml_04 mr_04">~</span>
          <date-picker v-model="regEndDt" :popover="{ visibility: 'click' }">
            <template v-slot="{ inputValue, inputEvents }">
              <input
                type="text"
                :value="inputValue"
                v-on="inputEvents"
                class="dt_base"
              />
            </template>
          </date-picker>
        </span>
        <base-button type="primary">검색</base-button>
      </div> -->
    <section class="cont_02">
      <div class="total_box addnumbox">
        <div>
          <div class="srch_total pl_40">
            <span class="srch_total_title">검색 :</span>
            <span class="total_count">{{ searchTotalItems }}</span>
            <span>명</span>
          </div>
          <div class="total ml_31">
            <span class="total_title">전체 :</span>
            <span class="total_count">{{ $n(totalItems) }}</span>
            <span>건</span>
          </div>
        </div>
        <span class="pr_40">
          <base-select
            :options="search.size.option"
            v-model="search.size.value"
            @input="fetch(1)"
            placeholder="선택"
          />
        </span>
      </div>
      <div class="table_area">
        <div>
          <base-table
            :useNo="true"
            :totalItems="totalItems"
            :currentPage="currentPage"
            :pageSize="pageSize"
            :loading="contentLoading"
            :fields="fields"
            :items="items"
            :isEdit="false"
            :clickedNum="clickedNum"
            @sort="onSortClick"
          />
        </div>
        <base-pagination
          :total="totalItems"
          :loading="contentLoading"
          :currentPage="currentPage"
          :pageCount="totalPages"
          :perPage="pageSize"
          @change="fetch"
        />
      </div>
      <div class="btn_right_wrapper">
        <base-button
          type="download"
          @click.prevent.stop="
            download(
              'advertisement?download=xls',
              '지자체 스탬프 획득 현황',
              'xls'
            )
          "
          :disabled="btnDisabled"
          >{{ downloadText }}</base-button
        >
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import client from "api-client";
import BaseSelect from "@/components/core/BaseSelect.vue";
import BaseButton from "@/components/core/BaseButton.vue";
import BaseTable from "@/components/core/BaseTable/BaseTable.vue";
import BasePagination from "@/components/core/BasePagination.vue";
import Dialog from "@/components/Dialog.vue";
import File from "@/common/file";
import { API_URL, SERVICE_API_URL } from "@/common/config";

export default {
  name: "StampAcquisitionClientPresent",
  components: {
    BaseSelect,
    BaseButton,
    BaseTable,
    BasePagination,
  },
  data() {
    return {
      downloadText: "엑셀 다운로드",
      btnDisabled: false,
      currentSort: "",
      clickedNum: 0,
      searchTotalItems: 0,
      clientList: [],
      missionList: [],
      fields: [
        {
          key: "group.name",
          width: "140px",
          label: "그룹",
        },
        {
          key: "stamp.picture",
          width: "120px",
          label: "스탬프",
          tag: "img",
        },
        {
          key: "stamp.name",
          width: "200px",
          label: "스탬프 명",
          useSort: true,
          clickedNum: 0,
        },
        {
          key: "gpsAcquisitionCount",
          width: "100px",
          label: "GPS",
          formatter: {
            name: "number",
          },
        },
        {
          key: "nfcAcquisitionCount",
          width: "100px",
          label: "NFC",
          formatter: {
            name: "number",
          },
        },
        {
          key: "bluetoothAcquisitionCount",
          width: "100px",
          label: "Bluetooth",
          formatter: {
            name: "number",
          },
        },
        {
          key: "qrcodeAcquisitionCount",
          width: "100px",
          label: "QR 코드",
          formatter: {
            name: "number",
          },
        },
        {
          key: "wifiAcquisitionCount",
          width: "100px",
          label: "WIFI",
          formatter: {
            name: "number",
          },
        },
        {
          key: "arAcquisitionCount",
          width: "100px",
          label: "AR",
          formatter: {
            name: "number",
          },
        },
        {
          key: "acquisitionMaleCount",
          width: "100px",
          label: "남자",
          formatter: {
            name: "number",
          },
        },
        {
          key: "acquisitionFemaleCount",
          width: "100px",
          label: "여자",
          formatter: {
            name: "number",
          },
        },
        {
          key: "acquisitionOtherCount",
          width: "100px",
          label: "기타",
          formatter: {
            name: "number",
          },
        },
        {
          key: "acquisitionCount",
          width: "100px",
          label: "획득수",
          formatter: {
            name: "number",
          },
          useSort: true,
          clickedNum: 0,
        },
        {
          key: "acquisitionTotalCount",
          width: "100px",
          label: "누적 획득수",
          formatter: {
            name: "number",
          },
        },
      ],
      items: [],
      totalItems: 0,
      totalPages: 0,
      currentPage: 1,
      pageSize: 10,
      contentLoading: true,
      selectedArea: "",
      searchTypeOptions: [{ name: "스탬프 명", value: "name" }],
      search: {
        type: "",
        q: "",
        startDate: {
          value: "",
          masks: {
            input: "YYYY-MM-DD",
          },
        },
        endDate: {
          value: "",
          masks: {
            input: "YYYY-MM-DD",
          },
        },
        client: {
          event: true,
          value: "",
          option: [],
        },
        mission: {
          event: true,
          value: "",
          option: [],
        },
        region: {
          event: true,
          option: [
            { name: "전체", value: "" },
            { name: "서울특별시", value: "서울특별시" },
            { name: "경기도", value: "경기도" },
            { name: "강원도", value: "강원도" },
            { name: "인천광역시", value: "인천광역시" },
            { name: "광주광역시", value: "광주광역시" },
            { name: "대구광역시", value: "대구광역시" },
            { name: "대전광역시", value: "대전광역시" },
            { name: "울산광역시", value: "울산광역시" },
            { name: "부산광역시", value: "부산광역시" },
            { name: "세종시", value: "세종시" },
            { name: "제주도", value: "제주도" },
            { name: "세종시", value: "세종시" },
            { name: "충청북도", value: "충청북도" },
            { name: "충청남도", value: "충청남도" },
            { name: "전라북도", value: "전라북도" },
            { name: "전라남도", value: "전라남도" },
            { name: "경상북도", value: "경상북도" },
            { name: "경상남도", value: "경상남도" },
          ],
          value: "",
        },
        size: {
          event: true,
          option: [
            {
              name: "10개씩",
              value: 10,
            },
            {
              name: "20개씩",
              value: 20,
            },
            {
              name: "30개씩",
              value: 30,
            },
            {
              name: "40개씩",
              value: 40,
            },
            {
              name: "50개씩",
              value: 50,
            },
          ],
          value: 10,
        },
      },
    };
  },
  created() {
    this.$Progress.start();
    this.fetch();
  },
  mounted() {
    this.$Progress.finish();
  },
  computed: {
    ...mapGetters(["isAuthenticated"]),
  },
  destroyed() {
    /**
     * destroyed
     */
  },
  methods: {
    download(url, name, type, params) {
      this.downloadText = "다운로드 중";
      this.btnDisabled = true;
      params = {
        page: this.currentPage,
        size: this.pageSize,
        download: "xls",
      };
      if (this.search.q && this.search.type) {
        params[this.search.type] = this.search.q;
      }
      url = `${API_URL}${SERVICE_API_URL}/stamp/acquisition/client`;
      File.download(url, name, type, params, (response) => {
        console.log(response);
        // if (response.status == 200) {
        this.btnDisabled = false;
        this.downloadText = "엑셀 다운로드";
        // }
      });
    },
    onSearch(event) {
      event.preventDefault();
      this.fetch();
    },
    onSortClick(value) {
      for (let i = 0; i < this.fields.length; i++) {
        this.fields[i].clickedNum = 0;
      }

      this.currentSort = value.item.key;
      if (this.currentSort.includes(".")) {
        const sortString = this.currentSort.split(".");
        this.currentSort =
          sortString[0] +
          sortString[1][0].toUpperCase() +
          sortString[1].slice(1);
      }
      this.clickedNum = this.clickedNum < 2 ? this.clickedNum + 1 : 0;
      this.fields[value.index].clickedNum = this.clickedNum;
      if (!this.contentLoading) {
        this.fetch(1);
      }
    },

    async fetch(page = 1) {
      this.currentPage = page;
      this.contentLoading = true;
      let params = {
        page: this.currentPage,
        size: this.pageSize,
      };

      if (this.search.client.value) {
        console.log(this.search.client.value);
        params.userId = this.search.client.value;
      }

      if (this.search.mission.value) {
        params.missionId = this.search.mission.value;
      }

      if (this.currentSort) {
        if (this.clickedNum == 1) {
          params.sort = `${this.currentSort}.asc`;
        } else if (this.clickedNum == 2) {
          params.sort = `${this.currentSort}.desc`;
        }
      }

      if (this.search.startDate.value && this.search.endDate.value) {
        params.startDate = this.$date(
          new Date(this.search.startDate.value)
        ).format("YYYY-MM-DD");
        params.endDate = this.$date(new Date(this.search.endDate.value)).format(
          "YYYY-MM-DD"
        );
      }

      if (this.search.q && this.search.type) {
        params[this.search.type] = this.search.q;
      } else if (0 < this.search.q.length) {
        this.$modal.show(
          Dialog,
          {
            title: "알림",
            content: "검색어 항목을 선택해 주세요.",
          },
          {
            width: 400,
            height: "auto",
          },
          {
            "before-open": () => {},
            "before-close": () => {},
          }
        );
        return;
      }

      Object.entries(this.search).forEach((row) => {
        if (row[1].event) {
          if (row[1].value.length !== 0) {
            params[row[0]] = row[1].value;
          }
        }
      });

      // client selectbox
      await client.clientUserFindAll({ size: 1000 }).then(
        (response) => {
          const { data } = response;
          for (let i = 0; i < data.items.length; i++) {
            this.clientList[i] = {
              name: data.items[i].name,
              value: data.items[i].id,
            };
          }
          this.search.client.option = this.clientList.sort(
            // 사용자설정 언어 기준 오름차순
            function (f, s) {
              f = f.name.toString();
              s = s.name.toString();
              return f.localeCompare(s);
            }
          );
        },
        () => {}
      );

      // mission selectbox
      await client.missionFindAll({ size: 1000 }).then(
        (response) => {
          const { data } = response;
          for (let i = 0; i < data.items.length; i++) {
            this.missionList[i] = {
              name: data.items[i].name,
              value: data.items[i].id,
            };
          }
          this.search.mission.option = this.missionList.sort(function (f, s) {
            f = f.name.toString();
            s = s.name.toString();
            return f.localeCompare(s);
          });
          // console.log(this.search.mission.option);
        },
        () => {}
      );

      await client.acquisitionClientStampFindAll(params).then(
        (response) => {
          const { data } = response;
          data.items.forEach((item) => {
            // 이미지
            if (item.stamp.picture.name) {
              item.stamp.picture = `${item.stamp.picture.url}${item.stamp.picture.path}?size=100`;
            }
            if (
              this.search.client.value.length > 0 ||
              this.search.mission.value.length > 0 ||
              (this.search.startDate.value && this.search.endDate.value)
            ) {
              this.searchTotalItems = data.totalItems;
            }

            // try {
            //   item.periodDay = `${this.$d(
            //     new Date(item.periodStartDay).getTime(),
            //     "short"
            //   )} ~ ${this.$d(new Date(item.periodEndDay).getTime(), "short")}`;
            //   if (
            //     new Date().getTime() <= new Date(item.periodEndDay).getTime()
            //   ) {
            //     item.operateStatus = "운영중";
            //   } else {
            //     item.operateStatus = "종료";
            //   }
            // } catch (e) {
            //   console.log(e);
            //   item.periodDay = "기간없음";
            //   item.operateStatus = "운영중";
            // }
          });

          this.items = data.items;
          if (this.totalItems == 0) {
            this.totalItems = data.totalItems;
          }
          this.totalPages = data.totalPages;
          this.contentLoading = false;
        },
        () => {
          // console.log(message);
          this.items = [];
          // this.totalItems = 0;
          this.totalPages = 0;
          this.searchTotalItems = 0;
          this.contentLoading = false;
        }
      );
    },
  },
};
</script>

<style scoped lang="scss">
@import "./index.scss";
</style>
